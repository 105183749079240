class HeaderWrapper extends HTMLElement {
  constructor() {
    super();
    this.scrollThreshold = 100; // Set scroll distance threshold
    this.search = this.querySelector(`#search-modal`);
    this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    this.template = this.getAttribute('template');
    this.templateSuffix = this.getAttribute('template-suffix');
    this.transHeader = this.template == "index" || this.templateSuffix == "gifting";
    this.logo = this.querySelector('#header-logo');
    this.labels = this.querySelectorAll('.js-label');
    this.wkIcon = this.querySelector('.wk-icon');
  }

  connectedCallback() {
      this.initScrollListener();
      this.initHoverListener();
      if(!this.transHeader) {
        this.firstElementChild.classList.remove('opacity-0');
        this.logo.classList.remove('invert');
        this.logo.classList.add('invert-0');
        this.wkIcon.classList.remove('invert');
        this.wkIcon.classList.add('invert-0');
      } else {
        this.children[1].classList.remove('border-b');
        this.wkIcon.classList.add('lg:group-hover:invert-0');
        this.labels.forEach(label => {
          label.classList.add('text-white')
        })
      }

      // if(this.template == "product") {
      //   this.search.classList.remove('transition-all', 'delay-100', 'duration-100', 'ease-in-out');
      //   this.search.classList.add('-translate-y-12', 'pointer-events-none', 'border-none');
      //   setTimeout(() => {
      //     this.search.classList.add('transition-all', 'delay-100', 'duration-100', 'ease-in-out');
      //   }, 300)
        
      // }

      this.handleScroll();
    
  }

  disconnectedCallback() {
    this.removeScrollListener();
  }

  initScrollListener() {
    this.handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollUp = false;
      if(scrollTop > this.scrollPosition) {
        //when scrolling down
        scrollUp = false;
      } else {
        //when scrolling up.
        scrollUp = true;
      }
      if (scrollTop > this.scrollThreshold) {
        this.classList.add('scrolled');
        this.scrolledDownHandler(scrollUp);
      } else {
        this.scrollTopHandler();
        this.classList.remove('scrolled');
      }



      this.scrollPosition = scrollTop;
    };

    window.addEventListener('scroll', this.handleScroll);
  }

  initHoverListener() {
    
    if(window.innerWidth > 1024){
    this.addEventListener("mouseenter", (event) => {
      this.searchOpen();
    });
    this.addEventListener("mouseleave", (event) => {
      //if closed
      if(!this.isSearchOpen){
        this.searchClose();
      } else {
        this.searchClose();
      }
      
    });
    }
  }

  removeScrollListener() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  searchToggle() {
    this.search.classList.toggle('-translate-y-12', 'pointer-events-none');
  }

  searchOpen(){
    this.search.classList.remove('-translate-y-11', 'pointer-events-none', 'z-0', 'opacity-0');
    this.search.classList.add('z-10', 'transition-all');
    this.search.setAttribute('open', 'true');
  }

  isSearchOpen() {
    return this.search.hasAttribute('open');
  }

  searchClose(){
    this.search.classList.add('-translate-y-11', 'pointer-events-none', 'z-0', 'opacity-0' );
    this.search.classList.remove('z-10', 'transition-all');
    this.search.removeAttribute('open');
  }

  scrolledDownHandler(scrollDelta) {
    if(this.transHeader) {
      // Add the functions you want to execute when the header is scrolled down
      if(this.template == "index" || this.templateSuffix == "gifting" || this.template == "product"){
      this.firstElementChild.classList.remove('opacity-0');
      this.parentElement.classList.add('border-b');
      this.logo.classList.remove('invert');
      this.logo.classList.add('invert-0');
      this.wkIcon.classList.remove('invert');
      this.wkIcon.classList.add('invert-0');
      
      this.labels.forEach(label => {
        label.classList.remove('text-white')
        label.classList.add('text-black');
      })
      }
    }
  }

  scrollTopHandler() {
    if(this.transHeader) {
      console.log('transparent')
      this.firstElementChild.classList.add('opacity-0');
      this.parentElement.classList.remove('border-b');
      this.logo.classList.remove('invert-0');
      this.logo.classList.add('invert');
      this.wkIcon.classList.remove('invert-0');
      this.wkIcon.classList.add('invert');
      this.labels.forEach(label => {
        label.classList.add('text-white')
        label.classList.remove('text-black');
      })
    }
  }
}

customElements.define('header-wrapper', HeaderWrapper);
